require('./bootstrap');
$(document).ready(function(){

$('.ir-arriba').click(function(){
		$('body, html').animate({
			scrollTop: '0px'
		}, 300);
	});

	if ( document.getElementById( "producto" )) {
		var reqId = "#producto";
		window.scrollTo(0, $(reqId).offset().top-20);
	}

	if ( document.getElementById( "webinars" )) {
		var reqId = "#webinars";

		window.scrollTo(0, $(reqId).offset().top-20);
	}

	$(window).scroll(function(){
		if( $(this).scrollTop() > 0 ){
			$('.ir-arriba').slideDown(300);
		} else {
			$('.ir-arriba').slideUp(300);
		}
	});

	window.functionforscroll = function(id){
	  var reqId = "#"+id;
	  window.scrollTo(0, $(reqId).offset().top-20);
	};

		var id = '#dialog';


 	// if(!isset($_COOKIE[$diclaimer_craverifem])){
	  //Get the screen height and width
	  var maskHeight = $(document).height();
	  var maskWidth = $(window).width();

	  //Set heigth and width to mask to fill up the whole screen
	  $('#mask').css({'width':maskWidth,'height':maskHeight});

	  //transition effect
	  $('#mask').fadeIn(500);
	  $('#mask').fadeTo("slow",1);
	  //Set heigth and width to mask to fill up the whole screen
	  $('#mask2').css({'width':maskWidth,'height':maskHeight});

	  //transition effect
	  $('#mask2').fadeIn(500);
	  $('#mask2').fadeTo("slow",1);

	  //Get the window height and width
	  var winH = $(window).height();
	  var winW = $(window).width();

	  //Set the popup window to center
//	  $(id).css('top',  winH/2-$(id).height()/2);
	  //$(id).css('left', winW/2-$(id).width()/2);

	  //transition effect
	  $(id).fadeIn(2000);

	  //if close button is clicked
	  $('.window .cerrar').click(function (e) {
			    //Cancel the link behavior
	    e.preventDefault();

	    $('#mask').hide();
	    $('#mask2').hide();
	    $('.window').hide();
	  });

	  //if mask is clicked
	  $('#mask').click(function () {
	    $(this).hide();
	    $('.window').hide();
	  });
	 // }
	 //
	 //

});
